var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{attrs:{"rounded":""}},[(_vm.isErrorGetList)?_c('common-error',{on:{"fetchData":function($event){_vm.isErrorGetList = false}}}):_c('section',[_c('FilterList',{attrs:{"pagination":_vm.pagination,"filters":_vm.filters},on:{"fetchOrders":function($event){return _vm.$emit('fetching')}}}),_c('v-data-table',{attrs:{"item-key":"index","show-expand":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"expanded":_vm.expanded,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":function (ref) {
	var value = ref.value;
	var item = ref.item;

	return value && _vm.fetchSubOrder(item);
},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.longitude",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.longitude)+" "+_vm._s(items.item.latitude))]),_c('span',{staticClass:"text-caption d-block grey--text text--darken-2"},[_vm._v(_vm._s(_vm.perseRelativeTime(items.item.updatedAt)))])]}},{key:"item.latestStatus",fn:function(items){return [_c('v-btn',{staticClass:"white--text w-100",attrs:{"color":_vm.colourDashboard(items.item.latestStatus),"small":""}},[_vm._v(" "+_vm._s(items.item.latestStatus)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var subItem = ref.item;
return [(subItem.subOrderLoading)?[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-progress-circular',{staticClass:"mx-auto",attrs:{"color":"primary","size":"30","indeterminate":""}})],1)],1)],1)]:(subItem.subOrder)?[_c('td',{staticClass:"white",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-2",attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headersSubInfo,"items":subItem.subOrder,"calculate-widths":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(subHeader,index){return _c('th',{key:index,style:({width: subHeader.width})})})}}],null,true)})],1)],1)],1)],1)],1)]:_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.order.PAGE_NAME)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }