<template>
  <v-container fluid>
    <v-sheet rounded>
      <common-error
        v-if="isErrorGetList"
        @fetchData="isErrorGetList = false"
      />
      <section v-else>
        <FilterList
          :pagination="pagination"
          :filters="filters"
          @fetchOrders="$emit('fetching')"
        />
        <v-data-table
          item-key="index"
          show-expand
          :loading="isLoading"
          :headers="headers"
          :items="items"
          :expanded.sync="expanded"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          @item-expanded="({value, item}) => value && fetchSubOrder(item)"
          @click:row="rowClicked"
        >
          <template v-slot:[`item.longitude`]=items>
            <span>{{ items.item.longitude }} {{ items.item.latitude }}</span>
            <span class="text-caption d-block grey--text text--darken-2">{{perseRelativeTime(items.item.updatedAt)}}</span>
          </template>
          <template v-slot:[`item.latestStatus`]="items">
            <v-btn
              :color="colourDashboard(items.item.latestStatus)"
              class="white--text w-100"
              small
            >
              {{items.item.latestStatus}}
            </v-btn>
          </template>
          <template
            v-slot:expanded-item="{ headers, item: subItem }"
          >
            <template v-if="subItem.subOrderLoading">
              <td :colspan="headers.length">
                <v-row justify="center">
                  <v-col cols="auto">
                    <v-progress-circular
                      class="mx-auto"
                      color="primary"
                      size="30"
                      indeterminate
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-else-if="subItem.subOrder">
              <td class="white" :colspan="headers.length">
                <v-container fluid>
                  <v-sheet elevation="2" class="pa-2">
                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="headersSubInfo"
                          :items="subItem.subOrder"
                          calculate-widths
                          hide-default-footer
                        >
                          <template v-slot:header="{props}">
                            <th
                              v-for="(subHeader, index) in props.headers"
                              :key="index"
                              :style="{width: subHeader.width}"
                            ></th>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-container>
              </td>
            </template>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.order.PAGE_NAME}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </section>
    </v-sheet>
  </v-container>
</template>

<script>
import {
  colourDashboard,
  perseRelativeTime,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import axios from 'axios';
import dayjs from 'dayjs';
import FilterList from './FilterList.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'ListOrders',
  components: {
    FilterList,
  },
  created() {
    source = CancelToken.source();
  },
  beforeDestroy() {
    const path = this.$route.path.split('/')[2];
    if (path !== 'dashboard') source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      items: [],
      headers: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'white--text primary',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.dashboard.orderNo,
          value: 'title',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.vehicle,
          value: 'transportName',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.vehicleNumber,
          value: 'companyTransportNo',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.label3PL,
          value: 'transporterName',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '120px',
        },
        {
          text: this.$_strings.dashboard.lastLocation,
          value: 'longitude',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '150px',
        },
        {
          text: this.$_strings.dashboard.status,
          value: 'latestStatus',
          align: 'center',
          class: 'white--text primary',
          cellClass: 'clickable',
          width: '120px',
          sortable: false,
        },
      ],
      headersSubInfo: [
        {
          text: '',
          value: 'data-table-expand',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'No Pesanan',
          value: 'title',
          class: 'black--text amber accent-3',
          sortable: false,
        },
        {
          text: 'Nama Customer',
          value: 'shipperName',
          class: 'black--text amber accent-3',
          sortable: false,
          // width: '15vw',
        },
        {
          text: 'Lokasi Asal',
          value: 'origin',
          class: 'black--text amber accent-3',
          sortable: false,
          // width: '15vw',
        },
        {
          text: 'Lokasi Tujuan',
          value: 'destination',
          class: 'black--text amber accent-3',
          sortable: false,
          // width: '15vw',
        },
        {
          text: 'Status',
          value: 'latestStatus',
          align: 'center',
          class: 'black--text amber accent-3',
          sortable: false,
          // width: '8vw',
          // sortable: false,
        },
      ],
      expanded: [],
      filters: {
        shipperId: +this.$route.query.shipperId || '',
        transporterId: +this.$route.query.transporterId || '',
        originLocationId: +this.$route.query.originLocationId || '',
        destinationLocationId: +this.$route.query.destinationLocationId || '',
        companyTransportNo: this.$route.query.companyTransportNo || '',
        latestStatus: this.$route.query.latestStatus || '',
        fromDate: this.$route.query.fromDate || dayjs().subtract('7', 'days').format('YYYY-MM-DD'),
        toDate: this.$route.query.toDate || dayjs().format('YYYY-MM-DD'),
        search: this.$route.query.search || '',
        sort: 'createdAt,desc',
      },
      pagination: defaultPagination(),
      totalItems: 0,
      isLoading: false,
      isErrorGetList: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchOrders();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    colourDashboard,
    skipEmptyStringObject,
    perseRelativeTime,
    getStatus(status) {
      switch (status) {
        case 'Pesanan Dibuat':
        case 'Menunggu Konfirmasi':
        case 'Menunggu Konfirmasi Transporter':
        case 'Menunggu Konfirmasi Driver':
          return 'UNCONFIRMED';
        case 'Terkonfirmasi':
        case 'Di Lokasi Penjemputan':
        case 'Di Lokasi Tujuan':
        case 'Pengiriman':
        case 'Penjemputan':
        case 'Ditolak':
        case 'BASP - Konfirmasi Shipper':
        case 'Dalam Proses':
        case 'BAP - Konfirmasi Shipper':
        case 'BA Penerimaan - Ganti Lokasi Tujuan':
        case 'BAP - Konfirmasi':
        case 'Sampai Pelabuhan':
        case 'Muat Barang':
        case 'Menunggu Muat Barang':
        case 'Dalam Penyebrangan':
        case 'Bongkar Muatan':
        case 'Dalam Penerbangan':
          return 'PROCESS';
        default:
          return 'FINISHED';
      }
    },
    rowClicked(item) {
      this.$router.push({
        name: 'dashboard-detail',
        params: {
          groupId: item.id,
          data: null,
        },
        query: {
          status: this.getStatus(item.latestStatus),
          groupTitle: item.title,
          shipmentDateStart: this.filters.fromDate,
          shipmentDateEnd: this.filters.toDate,
        },
      });
    },
    showLoading(boolean) {
      if (this.items.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async fetchOrders() {
      this.isErrorGetList = false;
      this.showLoading(true);
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
        ...this.filters,
      };
      try {
        const res = await this.$_services.dashboard.getDashboardList(this.skipEmptyStringObject(filters), source);
        let contents = [];
        if (res.data.contents) {
          contents = res.data.contents.map((i, index) => ({
            ...i,
            type: 'Order',
            subOrderLoading: false,
            index,
          }));
        }
        this.items = contents;
        this.totalItems = res.data.totalData;
      } catch {
        this.isErrorGetList = true;
      } finally {
        this.showLoading(false);
      }
    },
    async fetchSubOrder(order) {
      if (order.subOrder) return;
      try {
        order.subOrderLoading = true;
        const res = await this.$_services.dashboard.getListDashboardDetail(order.id);
        const contents = res.data.map((i) => ({ ...i, type: 'Sub Order' }));
        this.$set(order, 'subOrder', contents);
      } finally {
        order.subOrderLoading = false;
      }
    },
  },
};
</script>
