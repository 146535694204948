<template>
  <v-container fluid>
    <div
      v-if="isLoadingShipmentSummary"
      style="height:120px"
      class="d-flex align-center"
    >
      <v-progress-linear
        indeterminate
        color="cyan"
      ></v-progress-linear>
    </div>
    <common-error
      v-else-if="isErrorGetShipmentSummary"
      @fetchData="getShipmentSummary"
    />
    <v-row v-else justify="space-around">
      <v-col cols="auto">
        <summary-item
          status="new"
          :count="shipmentSummary.created"
          href="/main/pesanan/list/unconfirmed"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="problem"
          :count="shipmentSummary.accident"
          href="/main/masalah/list/unconfirmed"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="pickup"
          :count="shipmentSummary.pickup"
          href="/main/pesanan/list/process?latestStatus=PENJEMPUTAN,DI_LOKASI_PENJEMPUTAN"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="delivery"
          :count="shipmentSummary.delivery"
          :href="deliveryCount()"
        />
      </v-col>
      <v-col cols="auto">
        <summary-item
          status="complete"
          :count="shipmentSummary.completed && (shipmentSummary.completed > 999 ? '999+' : shipmentSummary.completed)"
          href="/main/pesanan/list/finished"
        />
      </v-col>
    </v-row>
    <v-row>
      <list-orders
        :key="key_list"
        @fetching="fetching"
      />
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import SummaryItem from './SummaryItem.vue';
import ListOrders from './ListOrders.vue';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  name: 'DashboardPage',
  components: {
    SummaryItem,
    ListOrders,
  },
  created() {
    source = CancelToken.source();
    this.getShipmentSummary();
  },
  beforeDestroy() {
    source.cancel('CANCELED_BY_THE_USER');
  },
  data() {
    return {
      key_list: 0,
      isLoadingShipmentSummary: false,
      isErrorGetShipmentSummary: false,
      shipmentSummary: {
        completed: '',
        confirm: '',
        accident: '',
        created: '',
        delivery: '',
        pickup: '',
      },
    };
  },
  methods: {
    deliveryCount() {
      const deliveryStatus = 'PENGIRIMAN,DI_LOKASI_TUJUAN,MENUNGGU_MUAT_BARANG,MUAT_BARANG,MENYEBRANG_PENGIRIMAN,DALAM_PENERBANGAN,DALAM_PENYEBRANGAN,SAMPAI_BANDARA,SAMPAI_PELABUHAN,BONGKAR_MUATAN';
      const href = `/main/pesanan/list/process?latestStatus=${deliveryStatus}`;
      return href;
    },
    fetching() {
      this.key_list += 1;
    },
    async getShipmentSummary() {
      try {
        this.isErrorGetShipmentSummary = false;
        this.isLoadingShipmentSummary = true;
        const res = await this.$_services.dashboard.getShipmentSummary(source);
        this.shipmentSummary = res.data;
      } catch {
        this.isErrorGetShipmentSummary = true;
      } finally {
        this.isLoadingShipmentSummary = false;
      }
    },
  },
};
</script>
