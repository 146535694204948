<template>
  <v-row
    dense
    align="center"
    class="pa-3"
  >
    <v-col cols="8">
      <v-row dense>
        <v-col cols="6" sm="auto">
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                :placeholder="$_strings.common.FROM_DATE"
                :value="dayjs(filters.fromDate).format('DD-MM-YYYY')"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.fromDate"
              no-title
              locale="id"
              :max="filters.toDate"
              @change="menuFromDate = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-none d-sm-flex align-center" cols="auto">-</v-col>
        <v-col cols="6" sm="auto">
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption"
                outlined
                :value="dayjs(filters.toDate).format('DD-MM-YYYY')"
                :placeholder="$_strings.common.TO_DATE"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="filters.fromDate"
              v-model="filters.toDate"
              no-title
              @change="menuToDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="auto">
          <FilterBy
            v-bind="$props"
            @applyFilters="applyFilters"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="3"
      class="ml-md-auto"
    >
      <v-text-field
        v-model="search"
        dense
        hide-details
        outlined
        class="caption"
        @keyup.native.enter="applyFilters"
        :placeholder="$_strings.order.SEARCHNO"
      >
        <template v-slot:prepend-inner>
          <v-icon @click="applyFilters">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import FilterBy from './FilterBy.vue';

export default {
  name: 'filter-order',
  props: {
    orderBy: String,
    filters: {
      type: Object,
      default: () => {},
    },
    pagination: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    FilterBy,
  },
  data() {
    return {
      search: this.$route.query.search || '',
      menuFromDate: false,
      menuToDate: false,
    };
  },
  watch: {
    'filters.fromDate': function fromDate(newVal, oldVal) {
      if (this.$route.query.fromDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            fromDate: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchOrders');
      }
    },
    'filters.toDate': function settoDate(newVal, oldVal) {
      if (this.$route.query.toDate !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            toDate: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchOrders');
      }
    },
  },
  methods: {
    dayjs,
    applyFilters() {
      this.filters.search = this.search;
      if (this.search !== this.$route.query.search) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            search: this.search,
            page: 1,
          },
        });
      }
      this.$emit('fetchOrders');
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
    font-size: 10px !important;
    color: red !important;
  }
</style>
